import React from "react";

import { Container, Text, Button, RichText } from "@atoms";
import { CardPerson } from "@molecules";

const FeaturedPeople = ({
  heading,
  subHeading,
  people,
  button,
  background,
  copy,
  details,
  message,
  id,
}) => {
  const showDetails = details === "show";
  const showFirstName = message === "Message FirstName";
  if (people && people.length > 0) {
    return (
      <div
        id={id}
        className={`bg-${background} relative overflow-hidden py-12 sm:py-20`}
      >
        <Container padding>
          <div className="relative z-10">
            <Text
              variant="mono--large-label"
              className={` ${background === "blue-dark" ? "text-white" : "text-blue"} text-center`}
            >
              {heading}
            </Text>
            <Text
              variant="h5"
              className={` ${
                background === "blue-dark"
                  ? "text-blue-light"
                  : "text-blue-dark"
              } text-center font-bold`}
            >
              {subHeading}
            </Text>
            {copy && (
              <Text
                variant="body"
                className={` ${background === "blue-dark" ? "text-white" : "text-blue-dark"} mx-auto mt-4 max-w-xl text-center`}
              >
                <RichText content={copy} />
              </Text>
            )}
            <div className="-mx-3 mb-4 mt-8 flex flex-wrap justify-center sm:-mx-6 md:mb-10 md:mt-16">
              {people.map((person, i) => {
                return (
                  <>
                    {people.length % 4 === 1 && i === people.length - 2 && (
                      <div className="w-full" />
                    )}
                    <div className="mb-12 w-1/2 px-3 sm:w-1/3 sm:px-6 md:w-1/4">
                      <CardPerson
                        {...person}
                        size="sm"
                        ar="8:5"
                        inverse={background === "blue-dark"}
                        details={showDetails}
                        altLayout={showFirstName}
                      />
                    </div>
                  </>
                );
              })}
            </div>
            <div>
              {button && button.label.length > 1 && (
                <div className="mb-3 flex items-center justify-center px-2">
                  <Button to={button.link} size="sm" color="orange">
                    {button.label}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }
  return null;
};

FeaturedPeople.defaultProps = {
  heading: null,
  subHeading: null,
  people: null,
  button: null,
  background: "blue-extralight",
  copy: null,
  details: "show",
  message: "Message FirstName",
};

export default FeaturedPeople;
