import React from "react";

import { Text, Image, Container, Button } from "@atoms";

const HeroHome = ({
  heading,
  subHeading,
  primaryButton,
  secondaryButton,
  image,
  id,
}) => {
  const ixParams = {
    exp: -1,
    con: -10,
    sat: 25,
    bri: -5,
  };
  return (
    <div id={id} className="relative bg-blue-dark">
      <div className="absolute left-0 right-0 top-0 h-1/2-screen bg-blue-dark" />
      <Container className="relative z-10">
        <div className="-mb-6 flex flex-col-reverse gap-8 pt-8 md:flex-row md:gap-0">
          {/* hero image */}
          <div className="relative z-0 w-full md:-mr-16">
            <div className="absolute inset-0 hidden h-full w-full md:block">
              <Image {...image} params={ixParams} fill fit="cover" />
              <div className="absolute bottom-0 right-0 top-0 z-20 hidden w-1/2 bg-gradient-to-r from-transparent to-black opacity-80 mix-blend-multiply md:block" />
            </div>
            <div className="relative aspect-video md:hidden">
              <div className="absolute inset-0 h-full w-full">
                <Image {...image} params={ixParams} fill fit="cover" />
              </div>
            </div>
          </div>
          {/* hero content */}
          <div className="relative z-10 flex flex-col gap-6 md:pb-16 md:pt-10 lg:min-w-[26rem]">
            {/* if heading */}
            {heading && (
              <h1 className="text-[2.5rem] font-bold text-white md:text-[4rem] lg:text-[5rem]">
                {heading}
              </h1>
            )}

            {/* if subheading */}
            {subHeading && (
              <Text
                variant="body--large"
                className="whitespace-pre-wrap font-bold leading-tight text-white"
              >
                {subHeading}
              </Text>
            )}

            {/* buttons */}
            <div className="-mx-2 flex flex-wrap gap-3">
              {/* if primaryButton && has label */}
              {primaryButton && primaryButton.label.length > 1 && (
                <Button to={primaryButton.link} size="sm" color="orange">
                  {primaryButton.label}
                </Button>
              )}

              {/* if secondaryButton && has label */}
              {secondaryButton?.label?.length > 1 && (
                <Button
                  to={secondaryButton.link}
                  size="sm"
                  color="white"
                  outline
                >
                  {secondaryButton.label}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

HeroHome.defaultProps = {
  subHeading: null,
  primaryButton: {
    link: null,
    label: null,
  },
  secondaryButton: {
    link: null,
    label: null,
  },
  image: null,
};

export default HeroHome;
